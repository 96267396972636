<header>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</header>

<body>
<div *ngIf="!fotoService.toggleFlag" class="sticky d-flex justify-content-center" routerLink="/home">
  <img class="voe-logo" src="../../assets/logo_V_voe.svg" alt="">
</div>
<div class="container" *ngIf="!isVideoSection && imagesLoaded | async">
  <div id="contenedor" *ngIf="showEvents"
       @fadeSlide
       (@fadeSlide.start)="fotoService.toggleFlag = true"
       (@fadeSlide.done)="fotoService.toggleFlag = false; fotoService.showEventHome = !fotoService.showEventHome" >
    <div class="contenedor-imagen d-flex justify-content-center" *ngFor="let photo of listData; last as isLast; first as isFirst">
      <div class="imagen">
        <div class="contenedor-fotos">
          <div id="imagen">
            <img [img-preloader]="photo.urlPhoto" class="img-fluid bordered-image" alt="Responsive image">
          </div>
          <div class="separacion">
            <div class="row w-100 justify-content-center text-center m-0 p-0">
              <div class="col-12 w-100 description">
                {{photo.description}}
              </div>
            </div>
            <div class="row w-100 justify-content-center text-center m-0 p-0">
              <div class="col-xl-6 col-md-12 makingOffDescription">
                {{photo.makingOffDescription}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="isVideoSection && imagesLoaded | async">
  <div id="contenedor" *ngIf="showEvents"
       @fadeSlide
       (@fadeSlide.start)="fotoService.toggleFlag = true"
       (@fadeSlide.done)="fotoService.toggleFlag = false; fotoService.showEventHome = !fotoService.showEventHome" >
    <div class="justify-content-center" *ngFor="let photo of listData; last as isLast; first as isFirst">
      <div class="mt-4">
        <div>
          <div class="container-video" style="width:100%; height:100%">
            <youtube-player class="responsive-iframe"  [videoId]="photo.urlPhoto"> </youtube-player>
          </div>
          <div class="separacion">
            <div class="row w-100 justify-content-center text-center m-0 p-0">
              <div class="col-12 w-100 description">
                {{photo.description}}
              </div>
            </div>
            <div class="row w-100 justify-content-center text-center m-0 p-0">
              <div class="col-xl-6 col-md-12 makingOffDescription">
                {{photo.makingOffDescription}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</body>
