import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ModeloService {

  constructor(private firebase: AngularFireDatabase) { }

  modelosList: AngularFireList<any>;

  form: FormGroup = new FormGroup({
      $key: new FormControl(null),
      fullName : new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      gender: new FormControl('1'),
      urlSocial: new FormControl(''),
      urlPhoto: new FormControl('', Validators.required)
  });

  initializeFormGroup(){
    this.form.setValue({
      $key: null,
      fullName: '',
      email: '',
      gender: '1',
      urlSocial: '',
      urlPhoto: ''
    })
  }

  getModelos(){
    this.modelosList = this.firebase.list('modelos');
    return this.modelosList.snapshotChanges();
  }

  insertModelo(modelo){
    this.modelosList.push({
      urlSocial: modelo.urlSocial,
      urlPhoto: modelo.urlPhoto,
      fullName: modelo.fullName,
      gender: modelo.gender,
      email: modelo.email
    });
  }

  updateModelo(modelo){
    this.modelosList.update(modelo.$key,{
      urlSocial: modelo.urlSocial,
      urlPhoto: modelo.urlPhoto,
      fullName: modelo.fullName,
      gender: modelo.gender,
      email: modelo.email
    });
  }

  deleteModelo($key: string){
    this.modelosList.remove($key);
  }

  populateForm(modelo){
    this.form.setValue(modelo);
  }

  /**getModeloName($key){
    if ($key==0){
      return "";
    }else{
      return _.find(this.modelosList, (obj) => {
        return obj.$key == $key;
      })['name'];
    }
  }**/
}
