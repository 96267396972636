import { Component, OnInit } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {FotoService} from '../shared/foto.service';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ContactComponent implements OnInit {

  constructor(public fotoService: FotoService, private notificationService: NotificationService, private spinner: NgxSpinnerService) { }



  showMore = false;
  contactForm: FormGroup;

  ngOnInit(): void {
    this.initContactForm();
  }

  private initContactForm(){
    this.contactForm = new FormBuilder().group({
        name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.email, Validators.required]),
        phone: new FormControl(null),
        budget: new FormControl(null, Validators.required),
        msg: new FormControl('', Validators.required)
    });
  }

  async sendEmail(e: Event) {
    this.contactForm.markAllAsTouched();
    e.preventDefault();
    if (this.contactForm.valid) {
      this.spinner.show('ball-newton');
      emailjs.sendForm('voeart.es', 'voeart_contact_template', e.target as HTMLFormElement, 'Ed1YW88QiwQZPB7l1')
      .then((result: EmailJSResponseStatus) => {
        this.contactForm.disable();
        this.spinner.hide('ball-newton');
        this.notificationService.success('Se ha contactado correctamente.');
      }, (error) => {
        this.notificationService.warn('Se ha producido un error, por favor inténtelo de nuevo.');
      });
    } else {
      this.notificationService.warn('Debe rellenar el formulario correctamente.');
    }

  }



}
