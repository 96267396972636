import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseStorageService } from '../../firebase-storage.service';
import { ModeloService } from '../../shared/modelo.service';
import { NotificationService } from '../../shared/notification.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modelo',
  templateUrl: './modelo.component.html',
  styleUrls: ['./modelo.component.scss']
})
export class ModeloComponent implements OnInit {

  constructor(public service: ModeloService, private firebaseStorage: FirebaseStorageService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<ModeloComponent>) { }

  public archivoForm = new FormGroup({
    archivo: new FormControl(null, Validators.required),
  });
  urlPublica: null;
  public datosFormulario = new FormData();
  mensajeArchivo= '';
  nombreArchivo= '';
  ngOnInit(): void {
    this.service.getModelos();
    if (this.service.form.get('urlPhoto').value!=''){
      this.urlPublica = this.service.form.get('urlPhoto').value;
    }

  }

  onClear(){
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.urlPublica = null;
  }

    //Evento que se gatilla cuando el input de tipo archivo cambia
    public cambioArchivo(event: any) {
      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
          this.nombreArchivo = event.target.files[i].name;
          this.datosFormulario.delete('archivo');
          this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name)
        }
        this.subirArchivo();
      } else {
        this.mensajeArchivo = 'No hay un archivo seleccionado';
      }
    }

  //Sube el archivo a Cloud Storage
  public async subirArchivo() {
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.firebaseStorage.referenciaCloudStorage(this.nombreArchivo);
    let tarea = await this.firebaseStorage.tareaCloudStorage(this.nombreArchivo, archivo);

    referencia.getDownloadURL().subscribe((URL) => {
      this.urlPublica = URL;
    });
  }

  onSubmit(){
    if (this.service.form.valid){
      if (!this.service.form.get('$key').value){
        this.service.insertModelo(this.service.form.value);
      }
      else{
        this.service.updateModelo(this.service.form.value);
      }
      this.service.form.reset();
      this.service.initializeFormGroup();
      this.urlPublica = null;
      this.notificationService.success('::Añadido correctamente');
      this.onClose();
    }
  }

  onClose(){
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close();
  }
}
