<mat-toolbar>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/photos">
          <mat-icon>add_a_photo</mat-icon>
          <span>Fotos</span>
        </button>
        <button mat-menu-item routerLink="/modelos">
          <mat-icon>supervisor_account</mat-icon>
          <span>Modelos</span>
        </button>
        <button mat-menu-item (click)="authService.SignOut()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Salir</span>
        </button>
      </mat-menu>
    <span>Administración</span>
    <span class="example-spacer"></span>
    <span>Voe</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
      <mat-icon>share</mat-icon>
    </button>
  </mat-toolbar>