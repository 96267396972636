<mat-toolbar class="d-flex justify-content-between">
    <span>
        {{service.form.controls['$key'].value?"Modificar Modelo":"Nuevo Modelo"}}
    </span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>
<form [formGroup]="service.form" class="normal-form" (submit)="onSubmit()">
    <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile>
            <div class="controles-container">
                <input type="hidden" formControlName="$key">
                <mat-form-field>
                    <input formControlName="fullName" matInput placeholder="Nombre completo*">
                    <mat-error>Este campo es obligatorio.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="email" matInput placeholder="Email">
                    <mat-error>Email inválido.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="urlSocial" matInput placeholder="Red social">
                </mat-form-field>
                <div class="add-bottom-padding">
                    <mat-radio-group formControlName="gender">
                        <mat-radio-button value="1">Hombre</mat-radio-button>
                        <mat-radio-button value="2">Mujer</mat-radio-button>
                        <mat-radio-button value="3">Otro</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="controles-container">

                <form [formGroup]="archivoForm">
                    <div *ngIf="urlPublica == null">
                        <label for="file-upload" class="custom-file-upload btn btn-dark">
                            Seleccionar foto.
                        </label>
                        <input id="file-upload" type="file" (change)="cambioArchivo($event)"
                            accept=".png,.jpg,.jpeg" formControlName="archivo" (change)="cambioArchivo($event)" />
                    </div>
                    <div *ngIf="urlPublica != null">
                        <img [src]="urlPublica" alt="..." class="img-thumbnail" style="height:200px;">
                    </div>
                </form>

                <mat-form-field>
                    <input formControlName="urlPhoto" matInput placeholder="URL Foto*" value={{urlPublica}}>
                    <mat-error>Este campo es obligatorio.</mat-error>
                </mat-form-field>
                <div class="d-flex justify-content-end">
                    <button class="mx-3" mat-raised-button color="primary" type="submit"
                        [disabled]="service.form.invalid">Enviar</button>
                    <button mat-raised-button color="warn" (click)="onClear()">Limpiar</button>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</form>