<div class="h-100 row panel w-100 align-items-center justify-content-center m-0">
  
    <div class="h-50 col-md-6 col-lg-4">
                <form [formGroup]="loginForm" class="py-5 px-5" style="background:gray">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Correo electrónico</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Introduce correo electrónico" formControlName="email" #email>
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                            else.</small>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Contraseña</label>
                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña" formControlName="password" #password>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Check me out</label>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button type="submit" class="btn btn-primary" (click)="authService.SignIn(email.value, password.value)">Login</button>
                        </div>
                    </div>
                    <ng-container *ngIf="errorMessage" class="mt-2">
                        <span class="error">{{errorMessage}}</span>
                      </ng-container>
                </form>
                
    </div>
    
    
</div>