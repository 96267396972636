import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { HomeComponent } from './home/home.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule} from '@angular/fire/database';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModelosComponent } from './modelos/modelos.component';
import { ModeloComponent } from './modelos/modelo/modelo.component';
import { ModeloService } from './shared/modelo.service';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialModule } from './material/material.module';
import { environment } from '../environments/environment';
import { ModeloListComponent } from './modelos/modelo-list/modelo-list.component';
import { FotosComponent } from './fotos/fotos.component';
import { FotoComponent } from './fotos/foto/foto.component';
import { FotoService } from './shared/foto.service';
import { FotoListComponent } from './fotos/foto-list/foto-list.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './shared/services/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CarouselModule, WavesModule } from 'angular-bootstrap-md';
import { DetailSectionComponent } from './detail-section/detail-section.component';
import { ImagePreloaderDirective } from './shared/directives/image-preloader.directive';
import { ContactComponent } from './contact/contact.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { OnlyNumbersDirective } from './shared/directives/only-numbers.directive';
import { FloatingContactButtonComponent } from './shared/floating-contact-button/floating-contact-button.component';
import {YouTubePlayerModule} from '@angular/youtube-player';

@NgModule({
  declarations: [
    AppComponent,
    AdminLoginComponent,
    HomeComponent,
    ModelosComponent,
    ModeloComponent,
    ModeloListComponent,
    FotosComponent,
    FotoComponent,
    FotoListComponent,
    AdminHeaderComponent,
    DetailSectionComponent,
    ImagePreloaderDirective,
    ContactComponent,
    OnlyNumbersDirective,
    FloatingContactButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MaterialModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    FlexLayoutModule,
    MatSidenavModule,
    CarouselModule,
    WavesModule,
    NgxSpinnerModule,
    YouTubePlayerModule
  ],
  providers:[ModeloService, FotoService, AuthService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[ModeloComponent]
})
export class AppModule { }
