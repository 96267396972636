/**
 * DOCUMENTACIÓN PARA REALTIME DATABASE FOTOS: https://bezkoder.com/angular-10-firebase-crud/
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { FotoListComponent } from './fotos/foto-list/foto-list.component';
import { FotosComponent } from './fotos/fotos.component';
import { HomeComponent } from './home/home.component';
import { ModelosComponent } from './modelos/modelos.component';
import { AuthGuard } from './shared/guard/auth.guard';
import {DetailSectionComponent} from './detail-section/detail-section.component';
import {ContactComponent} from './contact/contact.component';

const routes: Routes = [
  {
    path:'',redirectTo:'/home',pathMatch:'full'
  },
  {
    path:'home', component: HomeComponent
  },
  {
    path: 'admin-login', component: AdminLoginComponent
  },
  {
    path: 'modelos', component: ModelosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'photos', component: FotosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'detail-section/:section', component: DetailSectionComponent
  },
  {
    path: 'contacto', component: ContactComponent
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
