import { Component, OnDestroy, OnInit } from '@angular/core';

import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  mediaSub: Subscription;
  deviceXs: boolean;
  contactButtonHidden: boolean;
  constructor(
    public mediaObserver: MediaObserver,
    public router: Router){}
  ngOnInit(){
    this.mediaSub = this.mediaObserver.media$.subscribe((result:MediaChange)=>{
      this.deviceXs = result.mqAlias == 'xs' ? true : false;
    })
    this.router.events.subscribe( (e) => {
      if (e instanceof NavigationStart) {
        this.contactButtonHidden = e.url === '/contacto';
      }
    });
  }
  ngOnDestroy(){
    this.mediaSub.unsubscribe();
  }


}
