<div class="search-div">
    <button mat-raised-button (click)="onCreate()">
        <mat-icon>add</mat-icon>Crear
    </button>
    <mat-form-field class="search-form-field" floatLabel="never"> 
        <input matInput [(ngModel)]="searchKey" placeholder="Buscar" autocomplete="off" (keyup)="applyFilter()">
        <button mat-button matSuffix mat-icon-button arial-label="Limpiar" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</div>
<div class="mat-elevation-z8">
    <mat-table [dataSource]="listData" matSort>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.description}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="urlPhoto">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Foto</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <img [src]="element.urlPhoto" class="fotoModeloLista">
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-icon-button (click)="onEdit(row)"><mat-icon>launch</mat-icon></button>
                <button mat-icon-button color="warn" (click)="onDelete(row.$key)"><mat-icon>delete_outline</mat-icon></button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="6">
                Cargando datos...
            </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="6">
                Sin datos.
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row, columns: displayedColumns;"></mat-row>
        <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':listData!=null}"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(listData!=null && listData.data.length==0)}"></mat-footer-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>