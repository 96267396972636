import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModeloService } from '../../shared/modelo.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModeloComponent } from '../modelo/modelo.component';
import { NotificationService } from 'src/app/shared/notification.service';


@Component({
  selector: 'app-modelo-list',
  templateUrl: './modelo-list.component.html',
  styleUrls: ['./modelo-list.component.scss']
})
export class ModeloListComponent implements OnInit {

  constructor(private service: ModeloService,
    private dialog: MatDialog,
    private notificationService: NotificationService) { }

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['fullName','email','urlPhoto','actions']; //aquí añadimos los campos que queramos en la lista, si ponemos por ejemplo  'urlSocial' se visualizará la lista de urlSocial también (faltaría definirla en el HTML)
  @ViewChild(MatSort) sort:MatSort;
  @ViewChild(MatPaginator) paginator:MatPaginator;
  searchKey: string;


  ngOnInit(): void {
    this.service.getModelos().subscribe(
      list=>{
        let array = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });
        this.listData = new MatTableDataSource(array);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
        this.listData.filterPredicate = (data, filter) =>{
          return this.displayedColumns.some(ele => {
            return ele != 'actions' && data[ele].toLowerCase().indexOf(filter) != -1;
          });
        };
      }
    );
  }

  onSearchClear(){
    this.searchKey="";
    this.applyFilter();
  }

  applyFilter(){
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  onCreate(){
    this.service.initializeFormGroup();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(ModeloComponent, dialogConfig);
  }

  onEdit(row){
    this.service.populateForm(row);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(ModeloComponent, dialogConfig);
  }

  onDelete($key){
    if(confirm('¿Estás seguro de que quieres borrar este modelo?')){
      this.service.deleteModelo($key);
      this.notificationService.warn('! Borrado correctamente');
    }
    
  }
}
