<app-admin-header></app-admin-header>
<div class="container w-100">
    <div class="row">
        <div class="col-12 mt-2">
            <mat-toolbar class="dh-25">
                <span >Modelos</span>
            </mat-toolbar>
        </div>
    </div>
    <app-modelo-list></app-modelo-list>
</div>