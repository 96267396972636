<div *ngIf="fotoService.showEventHome"
     @fade
     (@fade.start)="fotoService.toggleFlag = true"
     (@fade.done)="fotoService.toggleFlag = false">

<nav class="container-fluid">
  <div class="sticky d-flex justify-content-center">
      <img class="voe-logo" src="../../assets/logo_voe.svg" alt="">
  </div>
</nav>

<div class="container-fluid h-100" style="color:white">
  <div class="row h-100">
    <div
         class="col-xl-6 col-lg-6 col-md-12 d-flex py-1 px-1" style="cursor:pointer" *ngFor="let category of categoriesList" (click)="goToDetailSection(category.fullName)">
      <div class="img-hover-zoom">
        <img [img-preloader]="category.urlPhoto" class="img-fluid" alt="">
      </div>

      <div class="centered">
        <h1 style="font-weight: bold; font-size:3.5rem">{{category.fullName}}</h1>
      </div>
    </div>
    </div>

</div>


<!-- Footer -->
<footer class="text-center text-lg-start bg-light text-muted">

  <!-- Section: Links  -->
<!--  <section class="pt-1"  style="background-color: red">-->
<!--    <div class="container text-center text-md-start">-->
<!--      &lt;!&ndash; Grid row &ndash;&gt;-->
<!--      <div class="row">-->
<!--        &lt;!&ndash; Grid column &ndash;&gt;-->
<!--        <div class="d-flex col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mt-2 justify-content-center align-content-center">-->
<!--          &lt;!&ndash; Links &ndash;&gt;-->
<!--          <h1 class="text-uppercase fw-bold contact" >Contacto</h1>-->
<!--        </div>-->
<!--        &lt;!&ndash; Grid column &ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; Grid row &ndash;&gt;-->
<!--    </div>-->
<!--  </section>-->
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
    © 2022 Copyright:
    <a class="text-reset fw-bold">VOEART</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->

</div>
