import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FotoService} from '../shared/foto.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {ActivatedRoute} from '@angular/router';
import {
  trigger, state, style, animate, transition, query, group
} from '@angular/animations';


@Component({
  selector: 'app-detail-section',
  templateUrl: './detail-section.component.html',
  styleUrls: ['./detail-section.component.scss'],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        group([
          query('#contenedor div:nth-child(odd)', [
            style({ opacity: 0, transform: 'translateX(-250px)' }),
            animate(
              1000,
              style({ opacity: 1, transform: 'translateX(0)' })
            )
          ]),
          query('#contenedor div:nth-child(even)', [
            style({ opacity: 0, transform: 'translateX(250px)' }),
            animate(
              1000,
              style({ opacity: 1, transform: 'translateX(0)' })
            )
          ])
        ])
      ]),
      transition(':leave', [
        group([
          query('#contenedor div:nth-child(odd)', [
            animate(
              1000,
              style({ opacity: 0, transform: 'translateX(-250px)' })
            )
          ]),
          query('#contenedor div:nth-child(even)', [
            animate(
              1000,
              style({ opacity: 0, transform: 'translateX(250px)' })
            ),
          ])
        ])
      ])
    ])
  ]
})
export class DetailSectionComponent implements OnInit {

  showEvents = true;
  imagesLoaded: Promise<boolean>;
  defaultImage = 'https://www.placecage.com/1000/1000';

  constructor(
    public fotoService: FotoService,
    private firebase: AngularFireDatabase,
    private route: ActivatedRoute,
  ) { }


  listData: any;
  isVideoSection = false;
  ngOnInit(): void {
    this.getCategories(this.route.snapshot.paramMap.get('section'));
  }

  onNavigate(path) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(path)) {
      url += 'http://';
    }
    url += path;
    window.open(url, '_blank');
  }

  // getVideoId(urlVideo: string): string{
  //   return urlVideo.split('=')[1];
  // }

  private getCategories(category: string){
    if(category == 'FILM' || category == 'BTS') {
      this.isVideoSection = true;
    }
    this.fotoService.getFotos().subscribe(
      async list => {
        let array = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });
        this.listData = array;
        await this.listData.forEach((foto, indexFoto) => {
          foto.modelos?.forEach((modelo, index, modelos) => {
            this.firebase.object("/modelos/" + modelo).valueChanges().subscribe(async modeloObtenida => {
              modelo = await modeloObtenida;
              if(modelo.fullName != category){
                this.listData.forEach((value,index)=>{
                  if(value.$key == foto.$key) this.listData.splice(index,1);
                });
              }
              modelos[index] = modelo;
            });
          });
        });
        let time = 0;
        let interval = setInterval(() => {
          if (time > 1) {
            this.imagesLoaded = Promise.resolve(true);
            clearInterval(interval);
          }
          time++;
        },10);
      });
  }

}



