import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, Form } from '@angular/forms';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FotoService {

  toggleFlag = true;
  showEventHome = true;

  constructor(private firebase: AngularFireDatabase) { }

  fotosList: AngularFireList<any>;
  formControl = new FormControl('');

  form: FormGroup = new FormGroup({
      $key: new FormControl(null),
      urlPhoto: new FormControl('', Validators.required),
      description : new FormControl('', Validators.required),
      makingOffVideo: new FormControl(''),
      makingOffDescription: new FormControl(''),
      modelos: new FormArray([])
  });

  get modelos(): FormArray {
    return this.form.get('modelos') as FormArray;
  }

  addModelo(){
    this.modelos.push(new FormControl(''));
  }

  removeModelo(index: number) {
    this.modelos.removeAt(index);
  }

  initializeFormGroup(){
    this.modelos.clear();
    this.form.setValue({
      $key: null,
      urlPhoto: '',
      description: '',
      makingOffVideo: '',
      makingOffDescription: '',
      modelos:[]
    })
  }

  getFotos(){
    this.fotosList = this.firebase.list('/fotos');
    return this.fotosList.snapshotChanges();
  }

  insertFoto(foto){
    this.fotosList.push({
      urlPhoto: foto.urlPhoto,
      description: foto.description,
      makingOffVideo: foto.makingOffVideo,
      makingOffDescription: foto.makingOffDescription,
      modelos: foto.modelos
    });
  }

  updateFoto(foto){
    this.fotosList.update(foto.$key,{
      urlPhoto: foto.urlPhoto,
      description: foto.description,
      makingOffVideo: foto.makingOffVideo,
      makingOffDescription: foto.makingOffDescription,
      modelos: foto.modelos
    });
  }

  deleteFoto($key: string){
    this.fotosList.remove($key);
  }

  populateForm(foto){
    foto.modelos?.forEach(modelo => {
      this.formControl =new FormControl();
      this.formControl.setValue(modelo)
      this.modelos.controls.push(this.formControl);
    });
    this.form.patchValue(foto);
  }



}
