import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseStorageService } from '../../firebase-storage.service';
import { ModeloService } from '../../shared/modelo.service';
import { NotificationService } from '../../shared/notification.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FotoService } from 'src/app/shared/foto.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-foto',
  templateUrl: './foto.component.html',
  styleUrls: ['./foto.component.scss']
})
export class FotoComponent implements OnInit {
  listaModelos = [];
  constructor(public modeloService: ModeloService, private firebaseStorage: FirebaseStorageService,
    public fotoService: FotoService,
    public notificationService: NotificationService,
    public dialogRef: MatDialogRef<FotoComponent>
    ) { }

  public archivoForm = new FormGroup({
    archivo: new FormControl(null, Validators.required),
  });
  urlPublica: null;
  public datosFormulario = new FormData();
  mensajeArchivo= '';
  nombreArchivo= '';
  nModelos=1;

  ngOnInit(): void {
    this.fotoService.getFotos();
    if (this.fotoService.form.get('urlPhoto').value!=''){
      this.urlPublica = this.fotoService.form.get('urlPhoto').value;
    }
    this.modeloService.getModelos().subscribe(
      list => {
        this.listaModelos = list.map(item =>{
          return {
            $key: item.key,
            ...item.payload.val()
          }
        });
      }
    );
  }

  onClear(){
    this.fotoService.form.reset();
    this.fotoService.initializeFormGroup();
    this.urlPublica = null;
  }

    //Evento que se gatilla cuando el input de tipo archivo cambia
    public cambioArchivo(event) {
      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
          this.nombreArchivo = event.target.files[i].name;
          this.datosFormulario.delete('archivo');
          this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name)
        }
        this.subirArchivo();
      } else {
        this.mensajeArchivo = 'No hay un archivo seleccionado';
      }
    }

  //Sube el archivo a Cloud Storage
  public async subirArchivo() {
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.firebaseStorage.referenciaCloudStorage(this.nombreArchivo);
    let tarea = await this.firebaseStorage.tareaCloudStorage(this.nombreArchivo, archivo);

    referencia.getDownloadURL().subscribe((URL) => {
      this.urlPublica = URL;
    });
  }



  onSubmit(){
    if (this.fotoService.form.valid){
      if (!this.fotoService.form.get('$key').value){
        this.fotoService.insertFoto(this.fotoService.form.value);
      }
      else{
        this.fotoService.updateFoto(this.fotoService.form.value);
      }
      this.fotoService.form.reset();
      this.fotoService.initializeFormGroup();
      this.urlPublica = null;
      this.notificationService.success('::Añadido correctamente');
      //this.onClose();
    }
  }

  onClose(){
    this.fotoService.form.reset();
    this.fotoService.initializeFormGroup();
    this.dialogRef.close();
  }
}
