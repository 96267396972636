<mat-toolbar class="d-flex justify-content-between">
    <span>
        {{fotoService.form.controls['$key'].value?"Modificar Foto":"Nueva Foto"}}
    </span>
    <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar> 

<div class="container-fluid ">

<div class="row justify-content-center">
    <div class="col-md-12">
<form [formGroup]="fotoService.form" class="normal-form">
    <mat-grid-list cols="2" rowHeight="500px">
        <mat-grid-tile>
            <div class="controles-container">
                <input type="hidden" formControlName="$key">
                <mat-form-field>
                    <input formControlName="description" matInput placeholder="Descripción*">
                    <mat-error>Este campo es obligatorio.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="makingOffVideo" matInput placeholder="URL Making Off">
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="makingOffDescription" matInput placeholder="Descripción Making Off">
                </mat-form-field>

                
                <button mat-button (click)="fotoService.addModelo()">
                    <div>Añadir Modelo</div> 
                </button>


               <ng-container formArrayName="modelos">
                    <div *ngFor="let _ of fotoService.modelos.controls; index as i">
                        <mat-form-field class="controles-container-modelos">
                            <mat-select [formControlName]="i" placeholder="Elegir modelo">
                                <mat-option>None</mat-option>
                                <ng-container *ngFor="let modelo of listaModelos">
                                    <mat-option value="{{modelo.$key}}">{{modelo.fullName}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <button  mat-button (click)="fotoService.removeModelo(i)" class="icon-display">
                            <mat-icon>remove icon</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>    
        </mat-grid-tile>

        <mat-grid-tile>
            <div class="controles-container">
                <form [formGroup]="archivoForm">
                    <div *ngIf="urlPublica == null">
                        <label for="file-upload" class="custom-file-upload btn btn-dark">
                            Seleccionar foto.
                        </label>
                        <input id="file-upload" type="file" (change)="cambioArchivo($event)" accept=".png,.jpg,.jpeg"
                            formControlName="archivo" (change)="cambioArchivo($event)" />
                    </div>
                    <div *ngIf="urlPublica != null">
                        <img [src]="urlPublica" alt="..." class="img-thumbnail" style="height:200px;">
                    </div>
                </form>
                <mat-form-field>
                    <input formControlName="urlPhoto" matInput placeholder="URL Foto*" value={{urlPublica}}>
                    <mat-error>Este campo es obligatorio.</mat-error>
                </mat-form-field>

                <div class="d-flex justify-content-end">
                    <button class="mx-3" mat-raised-button color="primary" (click)="onSubmit()"
                        [disabled]="fotoService.form.invalid">Enviar</button>
                    <button mat-raised-button color="warn" (click)="onClear()">Limpiar</button>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</form>
</div>
</div>
</div>