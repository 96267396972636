import { Component, OnInit } from '@angular/core';
import { FotoService } from 'src/app/shared/foto.service';
import { MatTableDataSource } from '@angular/material/table';
import * as photosList from '../../assets/photos.json'
import { AngularFireDatabase } from '@angular/fire/database';
import {Router} from '@angular/router';
import {ModeloService} from '../shared/modelo.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  categoriesList: any [];
  toggleFlag = false;

  constructor(private router: Router, private modeloService: ModeloService, public fotoService: FotoService) {}


  ngOnInit() {
    this.modeloService.getModelos().subscribe(
      list => {
        this.categoriesList = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          };
        });
      }
    );
  }

  goToDetailSection(section: string) {
    this.router.navigate(['/detail-section', section]);
  }

}



