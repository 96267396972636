// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCScuVK_R_C3fuHa4dRlVX1fdNi96Nshtw",
    authDomain: "voe-art-9d299.firebaseapp.com",
    databaseURL: "https://voe-art-9d299-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "voe-art-9d299",
    storageBucket: "voe-art-9d299.appspot.com",
    messagingSenderId: "534532225167",
    appId: "1:534532225167:web:89371ef065ffe5cc7758fa",
    measurementId: "G-HQDXCFYRLS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
