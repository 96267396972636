<div *ngIf="fotoService.showEventHome"
     @fade
     (@fade.start)="fotoService.toggleFlag = true"
     (@fade.done)="fotoService.toggleFlag = false">

<div class="container h-100">
  <div class="row text-center">
    <div class="sticky d-flex justify-content-center" routerLink="/home">
      <img class="voe-logo" src="../../assets/logo_V_voe.svg" alt="">
    </div>
  </div>
  <div class="row align-content-center justify-content-center mt-xl-5" style="height:90%; padding-top: 40px;">
    <div class="d-none d-lg-block col-xl-6 col-lg-12">
      <p class="contact-description">
        En muchas ocasiones, nos enfrentamos a la pregunta de por qué deberíamos elegir a una persona cuando hay tanto talento disponible. <br> <br>
        Cada individuo brilla en áreas únicas y distintivas. En mi caso, sobresalgo en la resolución de una amplia gama de problemas y situaciones complejas al abordar cualquier proyecto.  <br> <br>
        Este logro ha sido posible gracias a mi inquebrantable deseo de superar los límites, experimentar con nuevas ideas y absorber conocimientos de personas innovadoras. <br> <br>

        <br> <br>
        Esta trayectoria ha moldeado en mí a alguien capaz de identificar oportunidades donde otros ven obstáculos, transformando constantemente desafíos en soluciones tangibles y satisfactorias para los clientes.<br> <br>
        Estoy seguro de que encarno la elección idónea para el puesto requerido, y respaldado por mi experiencia y pasión, lograré traducir todas las metas pendientes en realidades palpables.<br> <br>

        <br> <br>

        Imagina cómo mi experiencia detrás de la cámara se fusionará con mi pasión incansable. Juntos, daremos vida a cada uno de los planes que anhelan alcanzar, convirtiendo cada visión en una impactante realidad.

      </p>
    </div>
    <div class="d-block d-sm-none d-md-block d-lg-none col-lg-12">
      <p class="contact-description">
        En muchas ocasiones, nos enfrentamos a la pregunta de por qué deberíamos elegir a una persona cuando hay tanto talento disponible.{{showMore?'':'..'}} <br> <br>
      </p>
      <div *ngIf="showMore">
        <p class="contact-description">
          En muchas ocasiones, nos enfrentamos a la pregunta de por qué deberíamos elegir a una persona cuando hay tanto talento disponible. <br> <br>
          Cada individuo brilla en áreas únicas y distintivas. En mi caso, sobresalgo en la resolución de una amplia gama de problemas y situaciones complejas al abordar cualquier proyecto.  <br> <br>
          Este logro ha sido posible gracias a mi inquebrantable deseo de superar los límites, experimentar con nuevas ideas y absorber conocimientos de personas innovadoras. <br> <br>

          <br> <br>
          Esta trayectoria ha moldeado en mí a alguien capaz de identificar oportunidades donde otros ven obstáculos, transformando constantemente desafíos en soluciones tangibles y satisfactorias para los clientes.<br> <br>
          Estoy seguro de que encarno la elección idónea para el puesto requerido, y respaldado por mi experiencia y pasión, lograré traducir todas las metas pendientes en realidades palpables.<br> <br>

          <br> <br>

          Imagina cómo mi experiencia detrás de la cámara se fusionará con mi pasión incansable. Juntos, daremos vida a cada uno de los planes que anhelan alcanzar, convirtiendo cada visión en una impactante realidad.
        </p>
      </div>
      <div class="row d-flex w-100 justify-content-center">
        <button class="mb-4 btn btn-light" (click)="showMore = !showMore">Ver {{showMore?'menos.':'más...'}}</button>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-8">
      <img class="img-fluid" src="../../assets/contact.png" alt="">
    </div>
  </div>

<div class="my-4 mat-elevation-z2 p-5">
  <ngx-spinner type="ball-newton-cradle" name="ball-newton"></ngx-spinner>
  <h1>Contactar</h1>
  <form [formGroup]="contactForm" class="contact-form row" (submit)="sendEmail($event)">
    <mat-form-field class="col-lg-3 col-sm-12" appearance="outline">
      <mat-label>Nombre</mat-label>
      <input formControlName="name" type="text" name="user_name" matInput>
      <mat-error *ngIf="contactForm.get('name').invalid">Debe introducir el nombre.</mat-error>
    </mat-form-field>
    <mat-form-field class="col-lg-3 col-sm-12" appearance="outline">
      <mat-label>Correo electrónico</mat-label>
      <input formControlName="email" type="email" name="user_email" matInput>
      <mat-error *ngIf="contactForm.get('email').invalid">Debe introducir un correo electrónico válido.</mat-error>
    </mat-form-field>
    <mat-form-field class="col-lg-3 col-sm-12" appearance="outline">
      <mat-label>Teléfono</mat-label>
      <input formControlName="phone" appOnlyNumbers=true type="text" inputmode="numeric" name="user_phone" matInput>
    </mat-form-field>
    <mat-form-field class="col-lg-3 col-sm-12" appearance="outline">
      <mat-label>Presupuesto</mat-label>
      <input formControlName="budget" appOnlyNumbers=true type="text" inputmode="numeric" name="user_budget" matInput>
      <mat-error *ngIf="contactForm.get('budget').invalid">Debe introducir un presupuesto.</mat-error>
    </mat-form-field>
    <mat-form-field class="col-12" appearance="outline">
      <mat-label>Mensaje</mat-label>
      <textarea formControlName="msg" name="message" matInput></textarea>
      <mat-error *ngIf="contactForm.get('msg').invalid">Debe introducir un mensaje.</mat-error>
    </mat-form-field>
    <div class="row d-flex w-100 justify-content-center">
      <button [disabled]="contactForm.disabled" type="submit" value="send" class="btn btn-light justify-content-center">Enviar</button>
    </div>
  </form>
</div>

  <br>


</div>

</div>
